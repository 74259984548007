<template>
    <component :is="layout || 'section'">
        <router-view></router-view>
    </component>
</template>

<script setup>
    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds
    const layout = computed(() => {
        return router.currentRoute.value.meta.layout
    })

    // Variables

    // Methods
    
</script>

<style lang="scss">
    #app {
        font-family: Montserrat-Regular;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #E9E8E9;
        text-align: left;
        color: #2c3e50;
    }
</style>