<template>
    <div class="row flex-center py-4 mb-5 mx-0" 
        :style="(data.configurations.show_lines) ? 'border: 1px solid red;' : ''">

        <div class="col-11">

            <div class="row d-flex d-xl-inline-grid justify-content-center" 
                :style="(data.configurations.show_lines) ? 'border: 1px solid blue;' : ''">
            
                <div class="col-12 col-lg-6 flex-center pb-5 pb-lg-0 px-5" 
                    :style="(data.configurations.show_lines) ? 'border: 1px solid black;' : ''"
                    >
                    <div class="bg-secondary w-100 radius-10 p-4" style="max-width: 760px;" v-if="!sended_email">

                        <div class="row p-0">

                            <div class="col-12 flex-start ms-2 mb-2">
                                <img :src="data.images.logo"
                                    height="100"/>
                            </div>

                            <div class="col-12 py-2 py-sm-3">
                                <input v-model="form.name"
                                    class="form-control"
                                    type="text"
                                    placeholder="Nombre completo"/>
                            </div>

                            <div class="col-12 col-sm-6 py-2 py-sm-3">
                                <input v-model="form.phone"
                                    class="form-control"
                                    type="tel"
                                    placeholder="Teléfono"/>
                            </div>

                            <div class="col-12 col-sm-6 py-2 py-sm-3">
                                <input v-model="form.email"
                                    class="form-control"
                                    type="email"
                                    placeholder="Email"/>
                            </div>

                            <div class="col-12 col-sm-6 py-2 py-sm-3">
                                <input v-model="form.city"
                                    class="form-control"
                                    type="text"
                                    placeholder="Ciudad"/>
                            </div>

                            <div class="col-12 col-sm-6 py-2 py-sm-3">
                                <input v-model="form.federative_entity"
                                    class="form-control"
                                    type="text"
                                    placeholder="Estado"/>
                            </div>

                            <div class="col-12 py-2 py-sm-3">
                                <textarea v-model="form.message"
                                    class="form-control" 
                                    style="height: 180px;"
                                    placeholder="Mensaje">
                                </textarea>
                            </div>

                            <div class="col-12 col-sm-6 pt-2 pt-sm-3">
                                <button @click="sendMail()"
                                    class="btn btn-primary text-white mb-1"
                                    style="width: 100%; max-width: 300px;"
                                    type="button">
                                    Enviar
                                </button>

                                <p class="lm--description-xxs text-white">
                                    Al hacer "click en enviar" aceptas nuestros términos y condiciones de privacidad 
                                </p>
                            </div>

                            <div class="col-12 col-sm-6 text-center">
                                
                            </div>
                        </div>
                    </div>

                    <div class="flex-center-center text-center w-100 p-0" v-else>
                        <div style="max-width: 400px;">
                            <h1 class="lm--title-md montserrat-bold text-primary">
                                <b>¡Gracias!</b>
                            </h1> <br>
                            <h4 class="text-white">
                                Nos pondremos en contacto contigo a la brevedad.
                            </h4>
                        </div>
                    </div>
                </div>


                <div class="col-12 col-lg-6 flex-center px-5" 
                    :style="(data.configurations.show_lines) ? 'border: 1px solid green;' : ''">

                    <div class="ratio ratio-1x1 radius-20">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15032.559099375965!2d-99.0792549!3d19.6213212!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d21c8c9f6f4b51%3A0xaf366ce40f2bf921!2sMuevetec%20-%20Motocarros%20Ecatepec!5e0!3m2!1ses-419!2smx!4v1684252439652!5m2!1ses-419!2smx" width="600" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="radius-10"></iframe>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"
    // JSON
    const data = reactive(require('ASSETS/json/data')).data

    // Variables
    
    const form = ref({
        name: null,
        phone: null,
        email: null,
        message: null,
        federative_entity: null,
        city: null
    })
    const sended_email = ref(false)

    // Methods
    const sendMail = () => {
        sended_email.value = true
        
        fetch(`${CONFIG.server[CONFIG.env].page_url}/send_email.php`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, /',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                name: form.value.name, 
                phone: form.value.phone, 
                email: form.value.email,
                federative_entity: form.value.federative_entity,
                city: form.value.city,
                message: form.value.message 
            })
        }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(response => console.log('Success:', response))

        $(document).ready(function() {
            $("body").children().each(function() {
                $(this).html($(this).html().replace(/&#8232;/g," "))
            })
        })
    }

</script>