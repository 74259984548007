<template>
    <section class="row flex-center bg-white position-relative py-4 mb-5 mx-0" 
        :style="(data.configurations.show_lines) ? 'border: 1px solid red;' : ''">
        <div class="square-abs"></div>

        <div class="col-11">
            <div class="row d-flex d-xl-inline-grid justify-content-center">
                
                <div class="col-10 col-xl-8" 
                    :style="(data.configurations.show_lines) ? 'border: 1px solid blue;' : ''">

                    <section class="mb-3" style="max-width: 750px;">
                        <div class="mb-1">
                            <span class="lm--description-md montserrat-semibold text-secondary"
                                v-html="data.information.copy2_1">        
                            </span>
                        </div>
                        <div class="mb-1">
                            <span class="lm--title-md"
                                v-html="data.information.copy2_2">        
                            </span>    
                        </div>
                        

                        <div class="mb-4">
                            <span class="lm--description-md montserrat-semibold text-secondary"
                                v-html="data.information.copy2_3">
                            </span>
                        </div>
                        <div>
                            <span class="lm--description-md"
                                v-html="data.information.copy2_4">
                            </span>
                        </div>
                    </section>

                    <div class="row mv-div d-flex d-sm- justify-content-center mt-5 mt-lg-4">
                        <div class="col-12 col-sm-11 col-md-10 col-lg-6 position-relative p-3 mb-4 mb-lg-0">
                            <div class="bg-secondary h-100 radius-25 py-3 px-3 px-lg-4">

                                <div class="square-box bg-white position-absolute radius-25 shadow-icon" style="top: -20px">
                                    <div class="square-content flex-center radius-25">
                                        <img :src="data.images.icon1" class="img-fluid">
                                    </div>
                                </div>

                                <div class="text-center mb-3">
                                    <span class="lm--description-md montserrat-bold text-white">
                                        Misión
                                    </span>
                                </div>
                                
                                <span class="lm--description-sm text-white"
                                    v-html="data.information.mision">
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-11 col-md-10 col-lg-6 position-relative p-3 mb-4 mb-lg-0">
                            <div class="bg-white h-100 radius-25 py-3 px-3 px-lg-4" 
                                style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);">

                                <div class="square-box bg-primary position-absolute radius-25 shadow-icon" style="top: -20px">
                                    <div class="square-content flex-center ">
                                        <img :src="data.images.icon2" class="img-fluid">
                                    </div>
                                </div>

                                <div class="text-center mb-3">
                                    <span class="lm--description-md montserrat-bold text-secondary">
                                        Visión
                                    </span>
                                </div>

                                <span class="lm--description-sm"
                                    v-html="data.information.vision">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-10 col-xl-4 d-flex justify-content-center justify-content-xl-end align-items mt-4 mt-xl-0" 
                    :style="(data.configurations.show_lines) ? 'border: 1px solid green;' : ''">

                    <div class="h-100">   
                        <img :src="data.images.product" 
                            alt="image_product" 
                            class="image-product img-fluid">
                    </div>
                </div>

            </div>
        </div>
        
    </section>
</template>

<script setup>
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // JSON
    const data = reactive(require('ASSETS/json/data')).data

    // Images

    // Variables

    // Lifecycle
    onMounted(async() => {
        
    })
</script>

<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';

    .mv-div {

        // @include upPixels(576px) { // Small
        //     width: 100%
        // }
        // @include upPixels(768px) { // Medium
        //     width: 100%
        // }
        // @include upPixels(992px) { // Large
        //     width: 100%
        // }
        @include upPixels(1200px) { // Extra large
            width: 130%
        }
        @include upPixels(1400px) { // Extra extra large
            width: 120%
        }
        
    }

    .image-product {
        border-radius: 65px 65px 65px 65px;

        @include upPixels(1200px) { // Extra large
            border-radius: 0px 65px 65px 0px;
        }
    }

</style>