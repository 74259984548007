<template>
    <div class="row d-flex d-xl-inline-grid justify-content-center">
        <div class="col-10 col-xl-12 pb-4 mb-4"
            :style="(data.configurations.show_lines) ? 'border: 1px solid blue;' : ''">

            <div class="mb-2">
                <span class="lm--description-md montserrat-semibold text-secondary"
                    v-html="data.information.copy43_1">
                </span>
            </div>
            
            <div class="mb-4">
                <span class="lm--title-md"
                    v-html="data.information.copy43_2">
                </span>    
            </div>

            <div class="mb-2">
                <span class="lm--description-md montserrat-semibold text-secondary"
                    v-html="data.information.copy43_3">
                </span>
            </div>
        </div>

        <div class="col-10 col-xl-12 mb-4" 
            :style="(data.configurations.show_lines) ? 'border: 1px solid green;' : ''">

            <div class="row flex-center-start">
                <div v-for="(item, i_item) of data.information.items" :key="i_item"
                    class="col-12 col-sm-6 col-xl-3 px-xl-5 mb-3">

                    <div :class="(i_item % 2 == 0) ? 'bg-primary' : 'bg-secondary'"
                        class="text-center radius-20 p-2 px-4 mb-2">

                        <span :class="(i_item % 2 == 0) ? '' : 'text-white'"
                            class="lm--description-md montserrat-semibold"
                            v-html="item.title">
                        </span>
                    </div>

                    <div v-for="(value, i_value) of item.values" :key="i_value" class="px-4">
                        <span class="lm--description-sm"
                            v-html="value">
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-10 col-xl-12 flex-center"
            :style="(data.configurations.show_lines) ? 'border: 1px solid green;' : ''">

            <img :src="data.images.product_types" class="img-fluid">
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // JSON
    const data = reactive(require('ASSETS/json/data')).data

    // Images

    // Variables

    // Methods

    // Lifecycle
    onMounted(async() => {
        
    })
</script>

<style lang="scss" scoped>

</style>