module.exports = {
	env: "local",
	server: {
		local: {
			page_url: 'http://localhost:8080',
			api: 'http://localhost:3006',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
            	'Authorization': 'Bearer 7bcc727f-5c74-4b8b-939e-d500a56d9d5f'
			}
		},
		dev: {
			page_url: 'https://g3nomics.monocilindrero.co/',
			api: 'https://g3nomic-api.monocilindrero.co',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
            	'Authorization': 'Bearer 7bcc727f-5c74-4b8b-939e-d500a56d9d5f'
			}
		},
		prod: {
			page_url: 'https://genomic360.com',
			api: 'https://api.genomic360.com',
			headers: {
				'Accept': 'application/json, text/plain, /',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer 7bcc727f-5c74-4b8b-939e-d500a56d9d5f'
			}
		}
	}
}