import main from 'ASSETS/images/main'
import product from 'ASSETS/images/product2'
import icon1 from 'ASSETS/images/icon1'
import icon2 from 'ASSETS/images/icon2'
import logo from 'ASSETS/images/logo'
import mapa from 'ASSETS/images/mapa'
import carrito from 'ASSETS/images/carrito'
import bici from 'ASSETS/images/bici'
import product_types from 'ASSETS/images/product_types'

import icon_phone from 'ASSETS/icons/phone'
import icon_menu from 'ASSETS/icons/menu'
import fb from 'ASSETS/images/fb'
import wpp from 'ASSETS/images/wpp'


import g1 from 'ASSETS/images/gallery/gorra_1'
import g2 from 'ASSETS/images/gallery/gorra_2'
import g3 from 'ASSETS/images/gallery/mandil_1'
import g4 from 'ASSETS/images/gallery/mandil_2'
import g5 from 'ASSETS/images/gallery/playera_1'
import g6 from 'ASSETS/images/gallery/playera_2'


export let data = { 


    configurations: {
        colors: {
            primary: "#FFC901",
            secondary: "#5FB167"
        },
        show_lines: false
    },
    navbar_options: [
        {
            label: 'INICIO',
            destination: '#inicio',
            type: 'left'
        },
        {
            label: 'NOSOTROS',
            destination: '#nuestra_empresa',
            type: 'left'
        },
        {
            label: 'FRANQUICIAS',
            destination: '#sistemas_especializados',
            type: 'left'
        },
        {
            label: 'INVERSIÓN',
            destination: '#ingenieria',
            type: 'left'
        },
        {
            label: 'GALERÍA',
            destination: '#galeria',
            type: 'left'
        },
        {
            label: 'CONTACTO',
            destination: '#contacto',
            type: 'right'
        }
    ],
    l_navbar_options: {
        item_n: 'CONTACTO',
        destination_n: '#contacto',
        phone: '55 4327 6724'
    },
    icons: {
        icon_phone,
        icon_menu
    },
    images: {
        //Section1
        main,
        //Section2
        product,
        icon1,
        icon2,

        logo,

        mapa,
        carrito,
        bici,

        product_types,

        fb,
        wpp,


        gallery: [
            g1,
            g2,
            g3,
            g4,
            g5,
            g6
        ]
    },
    information: {
        // Section2
        copy2_1: `Un concepto único en México`,
        copy2_2: `<span class="text-primary montserrat-bold">Sobre</span> 
            <span class="montserrat-regular"> Nosotros</span>`,
        copy2_3: `Nosotros no nada más te servimos un elote, te servimos una experiencia`,
        copy2_4: `El compromiso con la calidad de nuestros productos ha sido fundamental para una carta única y en constante innovación.`,
        mision: `Ser una empresa comprometida con el respeto a nuestros productores mexicanos del campo, transformando los productos que nos brindan en platillos únicos y deliciosos para toda la familia.`,
        vision: `Tener presencia en todos los estados de México, logrando ayudar a nuestros clientes a maximizar sus ingresos, en un negocio responsable con sus productos.`,

        // Section3
        copy3_1: `¡Tan sencillo como cosechar elotes!`,
        copy3_2: `<span class="text-primary montserrat-bold">Franquicia altamente</span> 
            <span class="text-secondary montserrat-regular"> rentable</span>`,
        copy3_3: `Desde cualquier punto de vista es una verdadera oportunidad para inversionistas que, como tú, están buscando un negocio de fácil manejo, ya sea para operarlo directamente o a través de empleados`,
        copy3_4: `Los números hablan por sí mismos`,
        points3: {
            left: [
                { text: 'Ventas diarias', value: '$1,250' },
                { text: 'Sueldos', value: '$5,000' },
                { text: 'Insumos', value: '$9,375' }
            ],
            right: [
                { text: 'Ventas mensuales', value: '$37,500' },
                { text: 'Gastos de Operación', value: '$11,250' },
                { text: 'Utilidad Mensual', value: '$11,875' }
            ]
        },
        copy3_5: `Beneficios de adquirir una franquicia Esquiteko`,
        benefits3: {
            b1: 'Uso y explotación de la marca.',
            b2: 'Manuales y documentos únicos.',
            b3: 'Exclusividad de zona geográfica.',
            b4: 'Entrenamiento inicial.',
            b5: 'Asistencia técnica.'
        },
        number_clients: '+1100',
        clients: `Clientes que consumen nuestros productos a en nuestra sucursal.`,
        copy3_6: `¡Encuentra con nosotros el antojo perfecto con sabor a elote!`,
        copy3_7: `Proyección Conservadora`,

        //Section4
        tab1: `MOTO FOOD TRUCK`,
        tab2: `MOTO BICI`,
        tab3: `CONCEPTO`,

        copy41_1: `Todavía faltan muchos lugares por llegar, te estamos esperando.`,
        copy41_2: `<span class="text-primary montserrat-bold">Diversos tipos de</span> 
            <span class="text-secondary montserrat-regular"> inversión</span>`,
        copy41_3: `Moto food truck`,
        copy41_4: `MÁS IVA CON FACTURA`,
        copy41_5: `$250,00MXN`,
        copy41_6: `Te entregamos:`,
        delivery_points: [
            'Ollas',
            'Utensilios',
            'Recetarios',
            'Manual de procedimientos',
            'Licencia de uso de marca por 3 años',
            'Terminal punto de venta con impresora móvil',
            '2 Uniformes',
            '2 Gorras',
            'Imágenes promocionales para la franquicia'
        ],


        copy42_1: `Únete a la franquicia más rentable de México`,
        copy42_2: `<span class="text-primary montserrat-bold">Diversos tipos de</span> 
            <span class="text-secondary montserrat-regular"> inversión</span>`,
        copy42_3: `Modelo 1`,
        copy42_4: `MÁS IVA CON FACTURA`,
        copy42_5: `$69,000MXN`,
        copy42_6: `Te entregamos tu isla comercial, insumos, uniformes y todo lo necesario para que comiences a vender... y a recuperar tu inversión.`,
        copy42_7: `Así de fácil es hacer negocio con una franquicia de Esquiteko. A la venta en llamativas islas en autoservicios y centros comerciales; los deliciosos, higiénicos, nutritivos y novedosos productos de maíz.`,
        copy42_8: `Te entregamos:`,
        delivery_points2: [
           'Food Bike',
           'Ollas',
           'Utensilios',
           'Recetarios',
           'Manual de Procedimientos',
        ],
        

        copy43_1: `Todavía faltan muchos lugares por llegar, te estamos esperando.`,
        copy43_2: `<span class="text-primary montserrat-bold">Un concepto</span> 
            <span class="text-secondary montserrat-regular"> diferente</span>`,
        copy43_3: `¡ Esquites con un toque de proteína y aderezos chidos !`,

        items: [
            {
                title: 'SABORES',
                values: [ 'Takis fuego', 'Doritos', 'Cheetos', 'Fritos' ]
            },
            {
                title: 'PROTEÍNAS',
                values: [ 'Suadero', 'Doritos',  'Queso manchego' ]
            },
            {
                title: 'SALSAS',
                values: [ 'Salsa roja', 'Salsa verde', 'Cacahuate' ]
            },
            {
                title: 'ADEREZOS',
                values: [ 'Ranch', 'Esquiteko' ]
            }
        ]

    }
}












