<template>
    <footer class="lm--footer flex-center pt-5 pb-0 pb-lg-4 pb-xl-0">
        <div class="w-100 mw-footer">

            <div class="row flex-between-start">
                <div class="col-lg-12 col-xl-3 text-center mb-5">
                
                    <div class="flex-center w-100">
                        <div style="max-width: 200px">
                            <img :src="data.images.logo" class="img-fluid">
                        </div>
                    </div>

                    <div class="my-2">
                        <span class="lm--description-lg montserrat-bold text-white">
                            Síguenos
                        </span>
                    </div>
                    
                    <div class="flex-center">
                        <a href="#" target="_blank">
                            <div class="me-1" style="width: 40px;">
                                <img :src="data.images.fb" class="img-fluid">
                            </div>
                        </a>
                        <a href="#" target="_blank">
                            <div class="mx-1" style="width: 40px;">
                                <img :src="data.images.wpp" class="img-fluid">
                            </div>
                        </a>
                        <a href="#" target="_blank">
                            <div class="mx-1" style="width: 40px;">
                                <img :src="data.images.wpp" class="img-fluid">
                            </div>
                        </a>
                        <a href="#" target="_blank">
                            <div class="ms-1" style="width: 40px;">
                                <img :src="data.images.wpp" class="img-fluid">
                            </div>
                        </a>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4 col-xl-3 mb-4 mb-lg-0">
                    <div class="title">
                        <span class="lm--description-xs montserrat-black text-white">
                            LEGALES
                        </span>
                    </div>

                    <div class="my-3">
                        <span class="lm--description-xxs text-white">
                            Términos y condiciones
                        </span>
                    </div>

                    <div class="my-3">
                        <span class="lm--description-xxs text-white">
                            Política de privacidad
                        </span>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6 col-xl-4 mb-4 mb-lg-0">
                    <div class="title">
                        <span class="lm--description-xs montserrat-black text-white">
                            CONTACTO
                        </span>
                    </div>

                    <div class="my-3">
                        <span class="lm--description-xxs text-white">
                            Av. Pio Pico 2060, Zona Centro Tijuana, B.C. 22000
                        </span>
                    </div>
                     
                    <div class="my-3">
                        <span class="lm--description-xxs text-white">
                            Teléfonos: <br>
                            55 4367 5545
                        </span>
                    </div>

                    <div class="my-3">
                        <span class="lm--description-xxs text-white">
                            Correo electrónico: <br>
                            informes@franquiciasobreruedas.com 
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </footer>

    <div class="row mx-0">
        <div class="col-12 flex-center bg-primary" style="min-height: 55px;">
            <span class="lm--description-xxs montserrat-bold">
                2022. ESQUITEKO. &#174; TODOS LOS DERECHOS RESERVADOS.
            </span>
        </div>

        <div class="col-12 flex-center bg-white" style="min-height: 55px;">
            <span class="lm--description-xxs montserrat-bold">
                DESARROLLADO POR 
                <a class="text-primary" href="https://monocilindrero.com" target="_blank">
                    MONOCILINDRERO.COM
                </a>
            </span>
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"
    // JSON
    const data = reactive(require('ASSETS/json/data')).data
</script>

<style lang="scss" scoped>
    @import '../assets/scss/minxin.scss';

    .lm--footer {
        background-color: #2A2D2E;

        .title {
            // font-size: 20px;
            // border-bottom: 1px solid white;
            padding-bottom: 10px;
        }

        .mw-footer {
            max-width: 300px;

            @include upPixels(460px) {
                max-width: 400px;
            }
            @include upPixels(576px) {
                max-width: 520px;
            }
            @include upPixels(768px) {
                max-width: 600px;
            }
            @include upPixels(992px) {
                max-width: 820px;
            }
            @include upPixels(1200px) {
                max-width: 1170px;
            }
        }
    }
</style>