<template>
    <section class="row flex-center mx-0">
        <div class="col-12 col-sm-11 flex-start-end">
            <div class="lm--tab me-1 me-sm-3" 
                @click="changeTab(1)">

                {{ data.information.tab1 }}
            </div>
            <div class="lm--tab mx-1 mx-sm-3" @click="changeTab(2)">
                {{ data.information.tab2 }}
            </div>
            <div class="lm--tab ms-1 ms-sm-3" @click="changeTab(3)">
                {{ data.information.tab3 }}
            </div>
        </div>
    </section>

    <section class="row flex-center bg-white position-relative pt-4 mb-5 mx-0" 
        :style="(data.configurations.show_lines) ? 'border: 1px solid red;' : ''">
        <div class="square-abs"></div>

        <div class="col-11 pb-4" v-show="tab_current == 1">
            <lm-investment1></lm-investment1>
        </div>

        <div class="col-11 pb-4" v-show="tab_current == 2">
            <lm-investment2></lm-investment2>
        </div>

        <div class="col-11" v-show="tab_current == 3">
            <lm-investment3></lm-investment3>
        </div>
    </section>
</template>

<script setup>
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // JSON
    const data = reactive(require('ASSETS/json/data')).data

    // Images

    // Variables
    const tab_current = ref(1)

    // Methods
    const changeTab = (tab) => {
        tab_current.value = tab
    }

    // Lifecycle
    onMounted(async() => {
        
    })
</script>

<style lang="scss" scoped>

</style>