<template>
    <section class="row flex-center bg-white position-relative py-4 mb-5 mx-0" 
        :style="(data.configurations.show_lines) ? 'border: 1px solid red;' : ''">

        <div class="square-abs"></div>

        <div class="col-11">
            <div class="row d-flex d-xl-inline-grid justify-content-center">
                <div class="col-10 col-xl-8" 
                    :style="(data.configurations.show_lines) ? 'border: 1px solid blue;' : ''">

                    <section class="mb-3" style="max-width: 850px;">
                        <div class="mb-1">
                            <span class="lm--description-md montserrat-semibold text-secondary"
                                v-html="data.information.copy3_1">        
                            </span>
                        </div>
                        <div class="mb-1">
                            <span class="lm--title-md"
                                v-html="data.information.copy3_2">        
                            </span>
                        </div>
                        
                        <div class="mb-4">
                            <span class="lm--description-md"
                                v-html="data.information.copy3_3">
                            </span>
                        </div>
                        <div class="shadow-text text-center radius-10 py-2">
                            <span class="lm--description-md montserrat-bold text-secondary"
                                v-html="data.information.copy3_4">
                            </span>
                        </div>

                        <div class="row mt-5" 
                            :style="(data.configurations.show_lines) ? 'border: 1px solid yellow;' : ''">

                            <div class="col-10 col-lg-6 px-4">
                                <div v-for="(point, i_point) of data.information.points3.left" :key="i_point"
                                    class="flex-between-start">
                                        <span class="lm--description-sm"
                                            v-html="point.text">
                                        </span>
                                        <span class="lm--description-sm montserrat-semibold text-secondary"
                                            v-html="point.value">
                                        </span>
                                </div>
                            </div>
                            <div class="col-10 col-lg-6 px-4">
                                <div v-for="(point, i_point) of data.information.points3.right" :key="i_point"
                                    class="flex-between-start">

                                        <span class="lm--description-sm"
                                            v-html="point.text">
                                        </span>
                                        <span class="lm--description-sm montserrat-semibold text-secondary"
                                            v-html="point.value">
                                        </span>
                                </div>
                            </div>
                        </div>

                        <div class="text-center my-3">
                            <span class="lm--description-md montserrat-semibold">
                                Retorno de inversión de 4 a 6 meses
                            </span>
                        </div>
                    </section>
                </div>

                <div class="col-10 col-xl-4 flex-center" 
                    :style="(data.configurations.show_lines) ? 'border: 1px solid green;' : ''">

                    <div>
                        <div>
                            <span class="lm--description-xs montserrat-semiboldi"
                                v-html="data.information.copy3_5">        
                            </span>
                        </div>

                        <div v-for="(benefit, i_benefits) of data.information.benefits3" :key="i_benefits"
                            class="ps-0 ps-xl-4">
                                <span class="lm--description-xs "
                                    v-html="benefit">
                                </span>
                        </div>

                        <div class="flex-center my-3">
                            <img :src="data.images.icon1" class="img-fluid float-left">

                            <div class="pe-2">
                                <span class="lm--description-lg montserrat-bold text-secondary"
                                    v-html="data.information.number_clients">        
                                </span>    
                            </div>
                            

                            <span class="lm--description-xs montserrat-semibold"
                                v-html="data.information.clients">        
                            </span>                            
                        </div>

                        <div class="mb-2">
                            <span class="lm--description-sm montserrat-black"
                                v-html="data.information.copy3_6">        
                            </span>
                        </div>

                        <div>
                            <span class="lm--description-lg montserrat-black text-primary"
                                v-html="data.information.copy3_7">        
                            </span>
                        </div>

                        <div class="flex-end-center">
                            <img :src="data.images.logo" class="img-fluid float-left">
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // JSON
    const data = reactive(require('ASSETS/json/data')).data

    // Images

    // Variables

    // Lifecycle
    onMounted(async() => {
        
    })
</script>

<style lang="scss" scoped>

</style>