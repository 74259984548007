import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

global.CONFIG = require('./page.config.js')

// Jquery
import jQuery from 'jquery'
global.$ = jQuery

// import BOOTSTRAP
import 'ASSETS/scss/bootstrap/bootstrap.scss'

// import CUSTOM
import 'ASSETS/scss/custom.scss'

// imports LIBS
// import _Example from './libs/example.js'


// import GLOBAL COMPONENTS
import { Navbar, AboutUs, Franchises, Investment, Investment1, Investment2, Investment3, Contact, Gallery, Footer, Slider } from 'COMPONENTS/index.js'


// TEMPLATES
import T_default from 'TEMPLATES/Tdefault'


// Create App
const app = createApp(App)
app.use(store)
app.use(router)
// templates
app.component('default', T_default)
// components
app.component('lm-navbar', Navbar)
app.component('lm-aboutus', AboutUs)
app.component('lm-franchises', Franchises)
app.component('lm-investment', Investment)
app.component('lm-investment1', Investment1)
app.component('lm-investment2', Investment2)
app.component('lm-investment3', Investment3)
app.component('lm-contact', Contact)
app.component('lm-gallery', Gallery)
app.component('lm-footer', Footer)
app.component('lm-slider', Slider)


// libs
// app.provide('$example', _Example)
//mount
app.mount('#app')
