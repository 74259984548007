<template>
    <div class="row d-flex d-xl-inline-grid justify-content-center">
        <div class="col-10 col-xl-12 pb-4" style="min-height: 200px;"
            :style="(data.configurations.show_lines) ? 'border: 1px solid blue;' : ''">

            <div class="mb-2">
                <span class="lm--description-md montserrat-semibold text-secondary"
                    v-html="data.information.copy41_1">
                </span>
            </div>
            
            <div class="mb-4">
                <span class="lm--title-md"
                    v-html="data.information.copy41_2">
                </span>    
            </div>

            <div class="flex-center" style="max-width: 845px">
                <div class="bg-white shadow-text text-center radius-10 py-2" style="width: 343px; z-index: 1;">
                    <span class="lm--description-md montserrat-bold text-secondary"
                        v-html="data.information.copy41_3">
                    </span>
                </div>

                <div class="bg-primary radius-20 text-center py-3 px-5" style="margin-left: -30px; z-index: 0">
                    <span class="lm--description-sm montserrat-bold"
                        v-html="data.information.copy41_4">
                    </span> <br>
                    <span class="lm--description-md montserrat-bold"
                        v-html="data.information.copy41_5">
                    </span>
                </div>
            </div>
        </div>

        <div class="col-10 col-xl-8" style="min-height: 200px;"
            :style="(data.configurations.show_lines) ? 'border: 1px solid blue;' : ''">

            <span class="lm--description-sm montserrat-semibold"
                v-html="data.information.copy41_6">
                
            </span>

            <ul style="list-style-type: none">
                <li class="lm--description-sm"
                    v-for="dp of data.information.delivery_points"
                    v-html="dp">
                </li>
            </ul>
        </div>

        <div class="col-10 col-xl-4 flex-center"
            :style="(data.configurations.show_lines) ? 'border: 1px solid green;' : ''">

            <img :src="data.images.carrito" class="img-fluid">
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // JSON
    const data = reactive(require('ASSETS/json/data')).data

    // Images

    // Variables

    // Methods

    // Lifecycle
    onMounted(async() => {
        
    })
</script>

<style lang="scss" scoped>

</style>