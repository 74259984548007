<template>
    <section id="home" class="container-fluid px-0">

        <img :src="data.images.main" alt="image_main" class="img-fluid mb-5" 
            style="filter: drop-shadow(0px 12px 18px rgba(0, 0, 0, 0.5));">

        <lm-aboutus></lm-aboutus>
        <lm-franchises></lm-franchises>
        <lm-investment></lm-investment>

        <lm-contact></lm-contact>
        <lm-gallery></lm-gallery>

    </section>
</template>

<script setup>
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // JSON
    const data = reactive(require('ASSETS/json/data')).data

    // Images

    // Variables

    // Lifecycle
    onMounted(async() => {
        
    })
</script>

<style lang="scss">
    #home {
        
    }
</style>