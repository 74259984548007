<template>
    <div class="col-12 flex-center pt-4" 
        :style="(data.configurations.show_lines) ? 'border-top: 1px solid red; border-left: 1px solid red; border-right: 1px solid red;' : ''">

        <div class="line-gray"></div>

        <button class="btn btn-primary lm--description-md montserrat-bold text-white radius-20 px-4"
            style="z-index: 1;">
                Galería
        </button>
    </div>

    <section class="row flex-center position-relative mx-0 my-5" 
        :style="(data.configurations.show_lines) ? 'border-left: 1px solid red; border-right: 1px solid red;' : ''">
        <div class="col-12">
            <lm-slider _id="gallery_unic" :items="data.images.gallery" :items_per_page="4"></lm-slider>
        </div>
    </section>

    <div class="col-12 flex-center pb-4"
        :style="(data.configurations.show_lines) ? 'border-bottom: 1px solid red; border-left: 1px solid red; border-right: 1px solid red;' : ''">

        <div class="line-gray"></div>
        <div style="height: 16px"></div>
    </div>
</template>

<script setup>
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // JSON
    const data = reactive(require('ASSETS/json/data')).data

    // Images

    // Variables

    // Lifecycle
    onMounted(async() => {
        
    })
</script>

<style lang="scss" scoped>
    .line-gray {
        width: 100%;
        position: absolute;
        background-color: #D9D9D9; 
        height: 16px; 
        z-index: 0;
    }
</style>