<template>
    <div id="lm--navbar" class="bg-dark position-custom" 
        style="margin-top: 48px; height: 60px; z-index: 5;">
        
        <nav class="flex-between-center h-100">

            <div class="flex-start h-100">
                <div class="flex-center h-100 ms-3 me-4" style="max-width: 53px">
                    <img :src="data.images.logo" class="img-fluid"/>
                </div>

                <template v-for="(item, index) in data.navbar_options" :key="index">
                    <div v-if="item.type == 'left'" class="flex-center h-100 mx-3">
                        <span class="lm--description-xs montserrat-bold text-white d-none d-xl-block">
                            <a :href="item.destination" class="decoration-none text-white text-nav">
                                {{ item.label }}
                            </a>
                        </span>
                    </div>
                </template>
            </div>

            <div class="flex-center h-100">

                <div class="me-3">
                    <span class="lm--description-xs montserrat-bold text-white d-none d-xl-block">
                        <a :href="data.l_navbar_options.destination_n" class="decoration-none text-white text-nav">
                            {{ data.l_navbar_options.item_n }}
                        </a>
                    </span>
                </div> 

                <span class="lm--description-xs montserrat-bold d-none d-xl-block ms-3 me-4">
                    <img :src="data.icons.icon_phone" class="me-2">

                    <a :href="`tel:${data.l_navbar_options.phone}`" 
                        class="decoration-none text-primary">
                            {{ data.l_navbar_options.phone }}
                    </a>
                </span>

                <img :src="data.icons.icon_menu" 
                    @click.stop="toggleNavbar"
                    class="d-block d-xl-none cursor-pointer ms-3 me-4">
            </div>
        </nav>



        <div class="position-relative" style="z-index: 2;">
            <nav v-show="toggle_menu"
                class="row position-absolute bg-dark" 
                :class="[animation_out ? 'out' : 'enter']"
                style="max-width: 250px; right: 0; top: 0px; border-top: 1px solid #1c2a3d;">

                <div v-for="(item, index) in data.navbar_options" :key="index" class="col-12 my-2">
                    <a :href="item.destination" 
                        class="decoration-none lm--description-xs montserrat-bold text-white text-nav">
                            {{ item.label }}
                    </a>
                </div>

                <div class="col-12 my-2">
                    <span class="lm--description-xs montserrat-bold">
                        <img :src="data.icons.icon_phone" class="me-2">

                        <a :href="`tel:${data.l_navbar_options.phone}`" 
                            class="decoration-none text-primary">
                                {{ data.l_navbar_options.phone }}
                        </a>
                    </span>
                </div>
            </nav>
        </div>
        
    </div>
</template>

<script setup>
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // JSON
    const data = reactive(require('ASSETS/json/data')).data

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds

    // Variables
    const toggle_menu = ref(false)
    const animation_out = ref(false)

    // Methods
    const toggleNavbar = () => {
        if(!toggle_menu.value) {
            animation_out.value = false
            toggle_menu.value = true
        }
        else {
            animation_out.value = true
            setTimeout(() => {
                toggle_menu.value = false
            }, 1000)
        }
    }
    
    // Lifecycle
    
    onMounted(async() => {
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            let nav = document.getElementById('lm--navbar')

            let device_width = document.documentElement.clientWidth
            if(device_width < 992) {
                nav.style.marginTop = "0px";

                try {
                    nav.classList.remove("position-custom")
                    nav.classList.add("position-fixed")
                } catch(e) {}
            }
            else {
                nav.classList.add("position-custom")
                nav.style.marginTop = (scroll > 48) ? "0px" : `${(48 - scroll)}px`
            }
        })
    })
    
</script>

<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';

    #lm--navbar {
        width: 100vw;
    }

    .position-custom {
        @include downPixels(992px) {
            position: block;
            margin-top: 0px !important;
        }

        @include upPixels(992px) {
            position: fixed;
        }
    }

    .enter {
        animation-name: fade-in;
        animation-delay: 0s;
        animation-duration: 1s;
    }

    .out {
        animation-name: fade-out;
        animation-delay: 0s;
        animation-duration: 1s;
    }

    @keyframes fade-in {
        0% {
            transform: translateX(120%);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes fade-out {
        0% {
            transform: translateX(0);
            opacity: 1;
        }

        100% {
            transform: translateX(120%);
            opacity: 0;
        }
    }
</style>