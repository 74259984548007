<template>
    <section id="slider" class="slider">
        <div :id="_id"
             class="slider--container" 
             :style="`width: ${percent_total_items}%;`">

            <div v-for="(item, i_item) in items" :key="`item-${i_item}`"
                :style="`width: ${percent_item}%;`"
                style="height: 100%;"
                class="px-0 px-lg-4 px-xl-5">
                    <img :src="item" class="img-fluid" />
            </div>
        </div>

        <div :id="`footer_${_id}`" class="slider--footer" 
            v-show="show_dots">

                <div class="slider-dot" 
                    @click="changeShowItem(i_dot)"
                    v-for="(dot, i_dot) in total_dots" :key="`dot-${i_dot}`">        
                </div>
        </div>
    </section>
</template>

<script setup>
    import { ref, reactive, computed, onMounted, onUnmounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Emits
    // const emit = defineEmits([''])

    // Props
    const props = defineProps({
        _id: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
            default: '',
        },
        items_per_page: {
            type: Number,
            required: true,
            default: 1
        },
        autoplay: {
            type: Boolean,
            required: false,
            default: true,
        },
        time_autoplay: {
            type: Number,
            required: false,
            default: 4000,
        },
        show_dots: {
            type: Boolean,
            required: false,
            default: true,
        }
    })

    // Computeds
    const total_items = computed(() => {
        return props.items.length
    })
    const total_dots = computed(() => {
        return Math.round(total_items.value / props.items_per_page)
    })
    const percent_total_items = computed(() => {
        return 100 * total_dots.value
    }) 
    const percent_item = computed(() => {
        return 100 / (total_dots.value * props.items_per_page)
    })
    const percent_page = computed(() => {
        return 100 / total_dots.value
    })

    // Variables
    const item_current = ref(null)
    const autoplay_interval = ref(null)


    // Methods
    const changeShowItem = (item) => {
        // console.log('changeShowItem', item)
        item_current.value = item
        
        // Remove dots actives
        let dots = document.getElementById(`footer_${props._id}`).getElementsByClassName("slider-dot")
        for(let i in dots) {
            try {
                dots[i].classList.remove("dot-active");    
            } catch(e) {}
        }
        // Set dot active
        dots[item_current.value].classList.add("dot-active");


        // Set item active
        let slider_container = document.querySelector(`#${props._id}`)
        let percent_current = (item == 0) ? 0 : (-1 * (percent_page.value * item))

        slider_container.style.transform = `translateX(${percent_current}%)`;
        //console.log(percent_current, 'percent_current')
    }


    // Lifecycle
    onMounted(async() => {
        item_current.value = 0
        changeShowItem(item_current.value)


        if(props.autoplay) {
            autoplay_interval.value = setInterval(function() {
                item_current.value = (item_current.value < total_dots.value - 1) ? item_current.value + 1 : 0
                changeShowItem(item_current.value)

            }, props.time_autoplay)
        }
    })
    onUnmounted(async() => {
        clearInterval(autoplay_interval.value)
    })

</script>

<style lang="scss" scoped>
    .slider {
        width: 100%;
        overflow-x: hidden;
        min-height: 120px;

        &--container {
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            transition: all .5s ease;
            transform: translateX(0%);
        }

        &--footer {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            .slider-dot {
                width: .7em;
                height: .7em;
                border-radius: 1em;
                background-color: rgb(204, 204, 204);
                margin: .6em;
                cursor: pointer;
            }
            .dot-active {
                background-color: #FF0000 !important;
            }
        }
    }
</style>
