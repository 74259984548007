<template>
    <lm-navbar></lm-navbar>
    <slot></slot>
    <lm-footer></lm-footer>
</template>

<script setup>
    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Images

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds

    // Variables

    // Methods

</script>


<style lang="scss">
    @import 'ASSETS/scss/minxin.scss';
</style>